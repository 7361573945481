<template>
  <b-container>
    <div class="text-center my-5">
      <MTBLoader v-if="items.length === 0" />
    </div>

    <!-- SEARCH -->
    <!-- <b-row v-if="items.length !== 0" class="mt-5 mb-3">
      <b-col md="6" class="mx-auto">
        <b-form-input border-variant="light" class="search-bar shadow mb-2" type="search" v-model="search" placeholder="Search Company..."></b-form-input>
      </b-col>
    </b-row> -->

    <!-- FILTERS -->
    <!-- <b-row v-if="items.length !== 0">
      <b-col>
        <b-button variant="light" size="sm" v-b-toggle.show-filters pill class="mt-3 mb-2"><b-icon icon="search" variant="primary"></b-icon> Advanced Filters</b-button>
        <p v-if="filteredItems.length !== 1" class="text-primary">{{ filteredItems.length }} Innovation Outposts</p>
        <p v-if="filteredItems.length !== items.length && filteredItems.length === 1" class="text-primary">{{ filteredItems.length }} Innovation Outpost</p>
      </b-col>
    </b-row>

      <b-collapse id="show-filters">
      <b-row v-if="items.length !== 0" class="mt-3 mb-4">
        <b-col md="4" class="">
          <b-form-select v-model="optionVertical" class="search-bar shadow mb-2" :options="optionVerticalsComputed"></b-form-select>
        </b-col>

        <b-col md="4" class="">
          <b-form-select v-model="optionCountry" class="search-bar shadow mb-2" :options="optionCountriesComputed"></b-form-select>
        </b-col>

        <b-col md="4" class="">
          <b-form-select v-model="optionType" class="search-bar shadow mb-2" :options="optionTypeList"></b-form-select>
        </b-col>

      </b-row>
    </b-collapse> -->

    <!-- CARDS GRID -->
    <b-row>
      <b-col class="mt-2" md="12">
        <div class="my-grid">
          <b-card id="grid-card" v-for="(item, index) in paginatedCards" v-bind:item="item" v-bind:key="item['ID']" v-bind:data-index="index" style="max-width: 20rem" border-variant="light" class="mb-2 rounded shadow cards-grid">
            <b-card-img class="mb-4" :src="item['Logo URL']" top @error="noImage"></b-card-img>

            <h5 class="mb-3"> <a :href="item['Website']" target="__blank">{{ item["Name"] }}</a></h5>

            <b-card-text>
              <!-- <p><b-icon icon="lightning" class="mr-2 text-primary"></b-icon>{{ item["Outpost Type"] }}</p> -->
              <p><v-icon name="cog" class="text-primary" scale="1" />  {{ item["Industry Verticals"][0] ? item["Industry Verticals"][0] : null }}</p>
              <p><v-icon name="dollar-sign" class="text-primary" scale="1" /> {{ item["Total Funding"] | formatCurrency }}</p>
              <p><v-icon name="map-marker-alt" class="text-primary" scale="1" /> {{ item["HQ Name"][0] }}</p>
              <!-- <a :href="item['Website']" target="__blank"><b-icon icon="link45deg" class="mr-2"></b-icon> Website</a> -->
            </b-card-text>
          </b-card>
        </div>
      </b-col>
    </b-row>

    <!-- PAGINATION -->
    <b-row v-if="items.length > 0">
      <b-col>
        <div class="mt-1">
          <b-pagination @page-click="scrollTop" pills class="mt-5" v-model="currentPage" :total-rows="rows" :per-page="perPage" align="center"></b-pagination>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import MTBLoader from "../../components/MTBLoader";
import util from "../../support/util";
export default {
  name: "CompantGrid",
  components: { MTBLoader },
  props: ["companyList"],
  computed: {
    companies() {
      return this.$props.companyList;
    },
  },
  data() {
    return {
      currentPage: 1,
      perPage: 8,
      search: "",
      optionVertical: null,
      optionCountry: null,
      optionType: null,
      optionTypeList: [{ value: null, text: "All Types" }, "Corporate Innovation Antenna", "Corporate Innovation Lab", "Corporate R&D Center", "CVC"],
    };
  },
  computed: {
    items() {
      return this.$store.state.items;
    },
    paginatedCards() {
      const { currentPage, perPage } = this;
      const start = (currentPage - 1) * perPage;
      const end = currentPage * perPage;
      return this.filteredItems.slice(start, end);
    },
    rows() {
      return this.filteredItems.length;
    },
    filteredItems() {
      return this.filterItemsSearch;
      // .filter((x) => this.filterItemsVertical.includes(x) && this.filterItemsCountry.includes(x) && this.filterItemsType.includes(x));
    },
    filterItemsVertical() {
      return this.filterItemByVertical(this.items);
    },
    filterItemsCountry() {
      return this.filterItemByCountry(this.items);
    },
    filterItemsType() {
      return this.filterItemByType(this.items);
    },
    filterItemsSearch() {
      return this.filterItembySearch(this.items);
    },
    // optionCountriesComputed() {
    //   var countriesList = [];
    //   this.items.forEach((item) => {
    //     if (item["Corporate HQ Country"][0]) {
    //       countriesList.indexOf(item["Corporate HQ Country"][0]) === -1 ? countriesList.push(item["Corporate HQ Country"][0]) : null;
    //     }
    //   });
    //   countriesList.sort()
    //   countriesList.unshift({ value: null, text: 'All Countries' })
    //   return countriesList
    // },
    // optionVerticalsComputed() {
    //   var verticalsList = []
    //   this.items.forEach((item) => {
    //     if (item["Industry"][0]) {
    //       verticalsList.indexOf(item["Industry"][0]) === -1 ? verticalsList.push(item["Industry"][0]) : null;
    //     }
    //   });
    //   verticalsList.sort()
    //   verticalsList.unshift({ value: null, text: 'All Industries' })
    //   return verticalsList
    // },
  },
  created() {
    this.noImage = util.noImage;
    this.loadLogo = util.loadLogo;
    this.scrollTop = util.scrollTop;

    const payload = {
      baseId: "app57KrpTuyJo9CJe",
      tableName: "Scaleups",
      view: "Scalers",
    };

    this.$store.dispatch("loadData", payload);
  },
  methods: {
    filterItembySearch(items) {
      return items.filter((item) => {
        return item["Name"].toLowerCase().includes(this.search.toLowerCase());
      });
    },
    filterItemByType(items) {
      if (this.optionType) {
        return items.filter((item) => {
          if (item["Outpost Type"]) {
            return item["Outpost Type"].includes(this.optionType);
          }
        });
      } else {
        return this.items;
      }
    },
    filterItemByVertical(items) {
      if (this.optionVertical) {
        return items.filter((item) => {
          if (item["Industry"]) {
            return item["Industry"].includes(this.optionVertical);
          }
        });
      } else {
        return this.items;
      }
    },
    filterItemByCountry(items) {
      if (this.optionCountry) {
        return items.filter((item) => {
          return item["Corporate HQ Country"].includes(this.optionCountry);
        });
      } else {
        return this.items;
      }
    },
  },
};
</script>

<style scoped>
.my-grid {
  display: grid;
  justify-items: center;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 1.5rem;
}
.my-grid > * {
  width: 100%;
  max-width: 20rem;
}
.search-bar {
  border-radius: 10px;
  border: none;
}
.card:hover {
  -webkit-transform: scale(1.02, 1.02);
  transform: scale(1.02, 1.02);
}
#grid-card .card:hover::after {
  opacity: 1;
}
.card {
  -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.card::after {
  content: "";
  border-radius: 5px;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  opacity: 0;
  -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.card-img-top {
  margin-top: 0vw;
  width: 100%;
  height: 1.5vw;
  object-fit: scale-down;
  max-width: 6rem;
  min-height: 3rem;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.cardUnlocked {
  -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  border: none;
}

.cardUnlocked .card-title#currentSummitTitle {
  color: white;
  font-size: 2.3rem;
  min-height: 300px;
}

#banner .card-title {
  font-size: 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.filterBar {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.title-banner {
  z-index: 0 !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
}

#banner {
  position: relative;
  text-align: center;
  color: white;
}

.search-banner {
  position: absolute;
  bottom: 0%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
